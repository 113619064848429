* {
  box-sizing: border-box;
}

body {
  font-family: Helvetica, sans-serif;
  background-color: rgba(61, 61, 61);
  background-size: auto;
  text-align: left;
  font-size: larger;
  margin: 0;
  max-width: 1980px;
}

#background {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-attachment: fixed;
  background-position: center center;
  min-height: 100vh;
  width: 100vw;
  padding-bottom: 2rem;
  z-index: -1;
}

.container{
  max-width: 1100px;
  margin: 0 auto;
}

/* Header */
h1 {
  font-weight: 349;
  font-size: 2.5rem;
  margin: 1rem auto;
  padding-bottom: .5rem;
}

.heading {
  display: flex;
  flex-direction: row;
  max-width: 100%;
}

/* .header-image {
  margin: 3%;
  border-radius: 50%;
  width: 24%;
  height: 24%;
  min-width: 5rem;
  min-height: 5rem;
  z-index: 100;
} */

.header-image {
  margin: 3% 3% 0% 3%;
  border-radius: 50%;
  width: 20%;
  height: 20%;
  max-width: 15rem;
  max-width: 15rem;
  min-width: 12rem;
  min-height: 12rem;
  z-index: 100;
  overflow: hidden;
}

.header-text {
  /* margin: 3rem 3rem 3rem -8rem;
  background-color: rgba(61, 61, 61);
  width: 100%;
  z-index: 1;
  padding: 0.75em 0 0.75rem 9rem;
  border-radius: 0 1rem 1rem 0; */
  margin: 4.8% 5% 2% -8%;
  background-color: rgba(61, 61, 61);
  width: 100%;
  z-index: 1;
  padding: 1% 0% 0% 9%;
  border-radius: 0 1rem 1rem 0;
  grid-row-start: auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  font-weight: lighter;
}

.links {
  padding: 2.5rem 1rem 3.5rem 1rem;
}

.links a {
  padding-right: 16%;
}

.text-hover {
  position: relative;
}

.text-hover .icon-text {
  visibility: hidden;
  width: 120px;
  text-align: center;
  border-radius: 6px;
  position: absolute;
  padding: 5px 0;
  z-index: 1;
  top: 100%;
  left: 1.5rem;
  margin-left: -65px;
  font-size: smaller;
}

.text-hover:hover .icon-text {
  visibility: visible;
}

/* Seasons */
.red a {
  color: rgb(255, 143, 79);
  text-decoration: none;
}

.red a:link:hover,
.red a:visited:hover {
  color: rgb(252, 95, 5);
  text-decoration: underline;
}

.red a:visited {
  color: rgb(209, 82, 9);
}

.white a {
  color: rgb(27 222 255);
  text-decoration: none;
}

.white a:link:hover,
.white a:visited:hover {
  color: rgb(0, 168, 235);
  text-decoration: underline;
}

.white a:visited {
  color: rgb(137, 215, 251);
}

.palegreen a {
  color: rgb(166, 255, 0);
  text-decoration: none;
}

.palegreen a:link:hover,
.palegreen a:visited:hover {
  color: rgb(219, 255, 100);
  text-decoration: underline;
}

.palegreen a:visited {
  color: rgb(0, 218, 11);
}

.orange a {
  color: rgb(255, 217, 0);
  text-decoration: none;
}

.orange a:link:hover,
.orange a:visited:hover {
  color: yellow;
  text-decoration: underline;
}

.orange a:visited {
  color: rgb(255, 196, 0);
}

.white {
  color: rgb(219 255 250);
}

.palegreen {
  color: palegreen;
}

.orange {
  color: rgb(255, 203, 134);
}

.red {
  color: rgb(255, 199, 167);
}

button {
  float: right;
  margin: 1rem;
  background-color: rgba(255, 255, 255, 0);
  padding: .5rem;
  border-radius: 50%;
}

button.white {
  border: 2px solid white;
  background-color: rgba(0, 0, 0, 0.5);
}

button.palegreen {
  border: 2px solid palegreen;
  background-color: rgba(0, 0, 0, 0.5);
}

button.orange {
  border: 2px solid orange;
  background-color: rgba(0, 0, 0, 0.5);
}

button.red {
  border: 2px solid red;
  background-color: rgba(0, 0, 0, 0.5);
}

button:hover {
  background-color: rgba(15, 10, 0);  
}

/* Body */
.box-styles {
  margin-top: 3rem;
  background-color: rgba(61, 61, 61);
  padding: .1rem 2rem .5rem 2rem;
  position: relative;
  border-radius: 10px;
  z-index: 100;
}

ul li {
  margin-top: 0;
}

.alien {
  width: 12rem;
  border-radius: 50%;
  margin-top: -6rem;
  margin-bottom: 3rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 200;
}

.box-styles ul:first-of-type {
  grid-row-start: auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  align-items: center;
}

.lists {
  grid-row-start: auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
}

.box-styles .lists {
  padding-left: 0;
  padding-right: 0;
}

.projects {
  padding-bottom: 0;
}

ol.lists {
  grid-template-columns: repeat(auto-fit, minmax(max(300px, 15vw), 3fr));
  list-style-position: inside;
  padding: 0rem 2rem;
  border-radius: 10px;
  z-index: 100;
}

ol.lists > * {
  padding-bottom: 1rem;
  margin: 0% 2% 3% 2%;
  grid-template-columns: repeat(auto-fit, minmax(400px, 2fr));
  /* box-shadow: 0 4px 8px 0 rgb(0, 0, 0); */
}

.lists p:first-of-type {
  padding-right: 1rem;
}

.box-styles p:last-of-type {
  margin-bottom: 5rem;
}

/* Footer */
footer {
  background-color: rgb(61, 61, 61);
  font-size: medium;
  border-radius: 1rem;
  text-align: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  margin-bottom: 3%;
  margin-bottom: 1rem;
  position: relative;
}

/* animation */
section .set, section .rain {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}

.set, .rain {
  z-index: 0;
}

section .set div, section .rain div {
  position: absolute;
  display: block;
}

section .set div:nth-child(1) {
  left: 20%;
  animation: animate 30s linear infinite;
  animation-delay: -15s;
}

section .set div:nth-child(2) {
  left: 70%;
  animation: animate 35s linear infinite;
  animation-delay: -5s;
}

section .set div:nth-child(3) {
  left: 30%;
  animation: animate 35s linear infinite;
  animation-delay: 0s;
}

section .set div:nth-child(4) {
  left: 80%;
  animation: animate 30s linear infinite;
  animation-delay: -4s;
}

section .set div:nth-child(5) {
  left: 60%;
  animation: animate 33s linear infinite;
  animation-delay: -10s;
}

section .set div:nth-child(6) {
  left: 10%;
  animation: animate 25s linear infinite;
  animation-delay: -2s;
}

section .set div:nth-child(7) {
  left: 50%;
  animation: animate 29s linear infinite;
}

section .set div:nth-child(8) {
  left: 90%;
  animation: animate 30s linear infinite;
}

@keyframes animate {
  0% {
    opacity: 0;
    top: -10%;
    transform: translateX(20px) rotate(0deg);
  }
  10%{
    opacity: 1;
  }
  20% {
    transform: translateX(-20px) rotate(45deg);
  }
  40% {
    transform: translateX(-20px) rotate(90deg);
  }
  60% {
    transform: translateX(-20px) rotate(90deg);
  }
  80% {
    transform: translateX(-20px) rotate(180deg);
  }
  100%{
    top: 110%;
    transform: translateX(-20px) rotate(225deg);
  }
}

section .rain div:nth-child(1) {
  left: 20%;
  animation: raindrops 15s linear infinite;
  animation-delay: -15s;
}

section .rain div:nth-child(2) {
  left: 70%;
  animation: raindrops 20s linear infinite;
  animation-delay: -5s;
}

section .rain div:nth-child(3) {
  left: 30%;
  animation: raindrops 20s linear infinite;
  animation-delay: 0s;
}

section .rain div:nth-child(4) {
  left: 80%;
  animation: raindrops 15s linear infinite;
  animation-delay: -4s;
}

section .rain div:nth-child(5) {
  left: 60%;
  animation: raindrops 18s linear infinite;
  animation-delay: -10s;
}

section .rain div:nth-child(6) {
  left: 10%;
  animation: raindrops 10s linear infinite;
  animation-delay: -2s;
}

section .rain div:nth-child(7) {
  left: 50%;
  animation: raindrops 14s linear infinite;
}

section .rain div:nth-child(8) {
  left: 90%;
  animation: raindrops 15s linear infinite;
}

@keyframes raindrops {
  0% {
    opacity: 1;
    top: -10%;
    transform: translateX(0vh);
  }
  75% {
    transform: translateX(-20px);
  }
  100% {
    opacity: 0;
    top: 110%;
    transform: translateX(-20px);
  }
}

/* mobile */
@media (max-width: 1000px) {
  .heading {
    flex-direction: column;
    margin: 0;
    width: 92%;
  }
  .header-image {
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
  .header-text {
    margin-top: -5rem;
    margin-left: 0%;
    padding-top: 4rem;
    border-radius: 10px;
    box-sizing: content-box;
  }
  .links a {
    padding: .5% 7%;
    margin: auto;
  }
  .text-hover .icon-text {
    margin-left: -40px;
  } 
  .box-styles {
    margin: 3rem auto;
    overflow: auto;
    text-overflow: ellipsis;
  }
  .lists {
    margin-bottom: 0;
  }
  ol.lists > * {
    padding-right: 1%;
    flex: auto;
    /* grid-template-columns: repeat(auto-fit, minmax(400px, 1fr)); */
  }
  .box-styles p:last-of-type {
    margin-bottom: 0;
  }
  .box-styles div:last-child p {
    margin-bottom: 6rem;
  }
}